import { useEffect, useState } from 'react';

import Button from './inputs/Button';
import Form from './inputs/Form';
import Input from './inputs/Input';
import Select from './inputs/Select';
import Table from './Table';
import useFetch from '../hooks/useFetch';
import { useNotifications } from '../contexts/NotificationContext';

import './ProfileAdmin.scss';


function AdminOrganizations(props) {

   const [organizations, setOrganizations] = useState([]);
   const [shownOrganizations, setShownOrganizations] = useState([]);

   useEffect(() => {

      if (props.organizations) {
         const newOrganizations = [];
         for (let i = 0; i < props.organizations.length; i++) {
            const organization = props.organizations[i];
            organization['index'] = i;
            newOrganizations.push(organization);
         }
         setOrganizations(newOrganizations);
         setShownOrganizations(newOrganizations);
      }

   }, [props.organizations]);

   const searchOrganizations = (query) => {

      setShownOrganizations(prevOrganizations => {
         const regex = new RegExp(query.toLowerCase());
         const newShownOrganizations = [];
         for (const organization of props.organizations) {
            if (query === '' || regex.test(organization.name)) {
               newShownOrganizations.push(organization);
            }
         }
         return newShownOrganizations;
      });

   }

   return (
      <div id="admin-panel-organizations">

         <Input 
            type="text"
            onInput={(e, value) => searchOrganizations(e.target.value)}
            placeholder="Zoek organisatie"
            icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9.145 18.29c-5.042 0-9.145-4.102-9.145-9.145s4.103-9.145 9.145-9.145 9.145 4.103 9.145 9.145-4.102 9.145-9.145 9.145zm0-15.167c-3.321 0-6.022 2.702-6.022 6.022s2.702 6.022 6.022 6.022 6.023-2.702 6.023-6.022-2.702-6.022-6.023-6.022zm9.263 12.443c-.817 1.176-1.852 2.188-3.046 2.981l5.452 5.453 3.014-3.013-5.42-5.421z"/></svg>}
            />

         {shownOrganizations.map((org, key) => {
            return (
               <div 
                  key={key}
                  className="organization"
                  onClick={ () => {
                     props.setEditOrganizationIndex(org.index);
                     props.setEditUserIndex(null);
                  } }
                  >
                  <h4>{org.name}</h4>
                  <span>{org.num_users} gebruikers</span>
                  <svg width="24" viewBox="0 0 24 24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M1 22h2v-22h18v22h2v2h-22v-2zm7-3v4h3v-4h-3zm5 0v4h3v-4h-3zm-6-5h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2z"/></svg>
                  {org.monthly_tokens &&
                     <span className="organization-payment">&euro;{org.monthly_tokens} {!isNaN(org.months_between_credit) && org.months_between_credit !== null && (org.months_between_credit === 1 ? "elke maand" : `per ${org.months_between_credit} maanden`)}</span>
                  }
               </div>
            );
         })}

         <div 
            className="organization add"
            onClick={() => {
               props.setEditOrganizationIndex("new");
            }}
            >
            <h4>Organisatie toevoegen</h4>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z"/></svg>
         </div>

      </div>
   )

}

function AdminAddUsers(props) {

   const [fadingOut, setFadingOut] = useState(false);
   const [organizations, setOrganizations] = useState([]);
   const [organizationID, setOrganizationID] = useState(null);

   const { addNotification } = useNotifications();

   const closeForm = (e) => {

      setFadingOut(true);
      setTimeout(() => {
         props.closeFormFn();
         setFadingOut(false);
      }, 350);

   }
   
   useEffect(() => {

      if (props.organizations) {
         const newOrganizations = [];
         for (const organization of props.organizations) {
            newOrganizations.push({name: organization.name, value: organization.id});
         }
         setOrganizations(newOrganizations);
      }

   }, [props.organizations]);

   return (
      <div 
         id="admin-panel-add-users"
         className={fadingOut ? "fading-out" : ""}
         >

         <div className="form-container">

            <Form
               method="POST"
               action={organizationID ? `/api/v1/organizations/${organizationID}/users` : ""}
               submitBtnText="Opslaan"
               additionalButton={<Button name="Annuleren" onClick={closeForm} noIcon />}
               submitCallback={() => {
                  addNotification('Gebruikers toegevoegd. Herlaad de pagina om deze wijziging te zien.');
                  closeForm();
               }}
               >

               <h3>Gebruikers toevoegen.</h3>
               <p>Voeg hier in bulk e-mailadressen toe.</p>

               <h4>Organisatie.</h4>
               <p>Selecteer de organisatie waaraan je gebruikers wilt toevoegen. Let op: het opwaarderingsschema is vervolgens direct van toepassing.</p>

               <Select
                  id="edit-user-organization-id"
                  placeholder="Organisatie"
                  name="organization_id"
                  options={organizations}
                  icon={<svg width="24" viewBox="0 0 24 24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M1 22h2v-22h18v22h2v2h-22v-2zm7-3v4h3v-4h-3zm5 0v4h3v-4h-3zm-6-5h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2z"/></svg>}
                  onChange={(e, newValue) => {
                     setOrganizationID(newValue);
                  }}
                  required
                  />

               <h4>E-mailadressen</h4>
               <p>Voer hier e-mailadressen in. Elk e-mailadres moet op een aparte regel.</p>

               <Input 
                  type="text"
                  name="emails"
                  placeholder="E-mailadressen"
                  onInput={(e) => {
                     e.target.value = e.target.value.replace(/[,;|]/, '\n')
                  }}
                  maxLength={61552}
                  multiline
                  required
                  />

            </Form>

         </div>

      </div>
   )

}

function AdminEditUser(props) {

   const [fadingOut, setFadingOut] = useState(false);

   const [user, setUser] = useState([]);
   const [organizations, setOrganizations] = useState([]);

   const editUserID = props.users[props.editUserIndex].id;

   const request = {
      user: {
          endpoint: "/api/v1/users/" + editUserID,
          method: "GET",
      }
   };
   const [data, loading, error] = useFetch(request);

   useEffect(() => {

      if (props.organizations) {
         const newOrganizations = [];
         for (const organization of props.organizations) {
            newOrganizations.push({name: organization.name, value: organization.id});
         }
         setOrganizations(newOrganizations);
      }

   }, [props.organizations]);

   useEffect(() => {

      if (!loading && data.user && data.user.data) {
         setUser(data.user.data);
      }

   }, [loading]);

   const updateUsers = () => {

      const firstName = document.getElementById('edit-user-first-name').value;
      const lastName = document.getElementById('edit-user-last-name').value;
      const numTokens = document.getElementById('edit-user-num-tokens').value;
      const organizationName = document.getElementById('edit-user-organization-id-show-value').value;

      // update table
      const newUsers = [...props.users];
      newUsers[props.editUserIndex]['full_name'] = firstName + ' ' + lastName;
      newUsers[props.editUserIndex]['organization'] = organizationName;
      newUsers[props.editUserIndex]['num_tokens'] = numTokens ? parseInt(numTokens).toLocaleString() : null;
      props.setUsers(newUsers);

      closeForm();

   }

   const closeForm = (e) => {

      setFadingOut(true);
      setTimeout(() => {
         props.closeFormFn();
         setFadingOut(false);
      }, 350);

   }

   return (
      <div 
         id="admin-panel-edit-user"
         className={fadingOut ? "fading-out" : ""}
         >

         <div className="form-container">

            <Form
               method="POST"
               action={"/api/v1/users/" + editUserID}
               submitBtnText="Opslaan"
               additionalButton={<Button name="Annuleren" onClick={closeForm} noIcon />}
               submitCallback={updateUsers}
               >

               <h3>Gebruiker bewerken.</h3>
               <p>Pas de gewenste gegevens aan. Klik op 'Opslaan' als je klaar bent.</p>

               <h4>Persoonsgegevens.</h4>
               <p>Zo verschijnt deze persoon op You Be Well. Het e-mailadres is niet te wijzigen.</p>

               <Input 
                  id="edit-user-email"
                  type="text"
                  placeholder="E-mail"
                  defaultValue={user ? user.email : ""}
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/></svg>}
                  readOnly
                  />

               <div className="two-inputs">

                  <div>
                     <Input 
                        id="edit-user-first-name"
                        name="first_name"
                        type="text"
                        placeholder="Voornaam"
                        defaultValue={user ? user.first_name : ""}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/></svg>}
                        required
                        />
                  </div>

                  <div>
                     <Input   
                        id="edit-user-last-name"
                        name="last_name"
                        type="text"
                        placeholder="Achternaam"
                        defaultValue={user ? user.last_name : ""}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/></svg>}
                        required
                        />
                  </div>

               </div>

               <h4>Organisatie.</h4>
               <p>Tegoed wordt toegekend op basis van het opwaarderingsschema van een organisatie.</p>
               <Select
                  id="edit-user-organization-id"
                  placeholder="Organisatie"
                  name="organization_id"
                  defaultValue={user.organization_id}
                  options={organizations}
                  icon={<svg width="24" viewBox="0 0 24 24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M1 22h2v-22h18v22h2v2h-22v-2zm7-3v4h3v-4h-3zm5 0v4h3v-4h-3zm-6-5h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2z"/></svg>}
                  />
               <Input   
                  id="edit-user-num-tokens"
                  name="num_tokens"
                  type="text"
                  placeholder="Tegoed"
                  defaultValue={user ? user.num_tokens : ""}
                  numericOnly
                  required
                  />

               <h4>Financiën.</h4>
               <p>Deze gegevens worden gebruikt voor facturatie en betalingen.</p>
               <div className="two-inputs">

                  <div>
                     <Input 
                        id="edit-user-coc-number"
                        name="coc_number"
                        type="text"
                        placeholder="KvK-nummer"
                        defaultValue={user ? user.coc_number : ""}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/></svg>}
                        />
                  </div>

                  <div>
                     <Input   
                        id="edit-user-vat-number"
                        name="vat_number"
                        type="text"
                        placeholder="Btw-nummer"
                        defaultValue={user ? user.vat_number : ""}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"/></svg>}
                        />
                  </div>

               </div>

               <Input 
                  id="edit-user-iban-number"
                  name="iban_number"
                  type="text"
                  placeholder="IBAN"
                  example="Bijv. NL20INGB0001234567"
                  defaultValue={user ? user.iban_number : ""}
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 21h-4v-11h4v11zm7-11h-4v11h4v-11zm7 0h-4v11h4v-11zm2 12h-22v2h22v-2zm-23-13h24l-12-9-12 9z"/></svg>}
                  minLength="18"
                  maxLength="22"
                  iban
                  />

               <Input 
                    id="edit-profile-beneficiary-name"
                    name="beneficiary_name"
                    type="text"
                    placeholder="Naam begunstigde"
                    example="Bijv. You Be Well B.V."
                    defaultValue={user ? user.beneficiary_name : ""}
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 21h-4v-11h4v11zm7-11h-4v11h4v-11zm7 0h-4v11h4v-11zm2 12h-22v2h22v-2zm-23-13h24l-12-9-12 9z"/></svg>}
                    minLength="1"
                    maxLength="100"
                    />

            </Form>

         </div>

      </div>
   )

}

function AdminEditOrganization(props) {

   const [fadingOut, setFadingOut] = useState(false);

   const [organization, setOrganization] = useState({});

   useEffect(() => {

      if (props.organizations && props.editOrganizationIndex !== null) {
         if (props.editOrganizationIndex === "new") {
            setOrganization({});
         } else {
            setOrganization(props.organizations[props.editOrganizationIndex])
         };
      }

   }, [props.organizations, props.editOrganizationIndex]);

   const updateOrganizations = (e, data) => {

      if (props.editOrganizationIndex === "new" && !data.data.organization_id) return;

      const name = document.getElementById('edit-org-name').value;
      const monthlyTokens = document.getElementById('edit-org-monthly-tokens').value;
      const monthsBetweenCredit = parseInt(document.getElementById('edit-org-months-between-credit-value').value);
      const creditDayOfMonth = parseInt(document.getElementById('edit-org-credit-day-of-month-value').value);
      const startTokens = parseInt(document.getElementById('edit-org-start-tokens').value);

      const newOrganizations = [...props.organizations];
      const editOrganizationIndex = props.editOrganizationIndex === "new" ? newOrganizations.length : props.editOrganizationIndex;

      // add new organization if applicable 
      if (props.editOrganizationIndex === "new") {
         newOrganizations.push({id: data.data.organization_id, num_users: 0});
      }

      newOrganizations[editOrganizationIndex]['name'] = name;
      newOrganizations[editOrganizationIndex]['monthly_tokens'] = monthlyTokens;
      newOrganizations[editOrganizationIndex]['months_between_credit'] = monthsBetweenCredit;
      newOrganizations[editOrganizationIndex]['credit_day_of_month'] = creditDayOfMonth;
      newOrganizations[editOrganizationIndex]['start_tokens'] = startTokens;
      props.setOrganizations(newOrganizations);

      closeForm();

   }

   const closeForm = (e) => {

      setFadingOut(true);
      setTimeout(() => {
         props.closeFormFn();
         setFadingOut(false);
      }, 350);

   }

   return (
      <div 
         id="admin-panel-edit-org"
         className={fadingOut ? "fading-out" : ""}
         >

         <div className="form-container">

            <Form
               method="POST"
               action={"/api/v1/organizations" + (organization.id ? "/" + organization.id : "")}
               submitBtnText="Opslaan"
               additionalButton={<Button name="Annuleren" onClick={closeForm} noIcon />}
               submitCallback={updateOrganizations}
               >

               <h3>Organisatie {props.editOrganizationIndex === "new" ? "toevoegen" : "bewerken"}.</h3>
               <p>Pas de gewenste gegevens aan. Klik op 'Opslaan' als je klaar bent.</p>

               <h4>Beschrijving.</h4>
               <p>Zo zien gebruikers hun organisatie.</p>

               <Input 
                  id="edit-org-name"
                  name="organization_name"
                  type="text"
                  placeholder="Naam van de organisatie"
                  defaultValue={organization ? organization.name : ""}
                  icon={<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M1 22h2v-22h18v22h2v2h-22v-2zm7-3v4h3v-4h-3zm5 0v4h3v-4h-3zm-6-5h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-12-4h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm8 0h-2v2h2v-2z"/></svg>}
                  required
                     />

               <h4>Opwaarderen.</h4>
               <p>Met deze gegevens wordt het tegoed van gebruikers bij deze organisatie opgewaardeerd.</p>

               <Input 
                  id="edit-org-start-tokens"
                  name="start_tokens"
                  type="text"
                  placeholder="Starttegoed nieuwe gebruiker"
                  defaultValue={organization ? organization.start_tokens : ""}
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 8v-2c0-1.104.896-2 2-2h18c1.104 0 2 .896 2 2v2h-22zm24 7.5c0 2.485-2.015 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-2.156-.882l-.696-.696-2.116 2.169-.991-.94-.696.697 1.688 1.637 2.811-2.867zm-8.844.882c0 1.747.696 3.331 1.82 4.5h-12.82c-1.104 0-2-.896-2-2v-7h14.82c-1.124 1.169-1.82 2.753-1.82 4.5zm-5 .5h-5v1h5v-1zm3-2h-8v1h8v-1z"/></svg>}
                  maxLength="4"
                  max="1000"
                  inputMode="numeric"
                  numericOnly
                  />

               <div className="two-inputs">

                  <div>
                     <Input 
                        id="edit-org-monthly-tokens"
                        name="monthly_tokens"
                        type="text"
                        placeholder="Tegoed per opwaardering"
                        defaultValue={organization ? organization.monthly_tokens : ""}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 8v-2c0-1.104.896-2 2-2h18c1.104 0 2 .896 2 2v2h-22zm24 7.5c0 2.485-2.015 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-2.156-.882l-.696-.696-2.116 2.169-.991-.94-.696.697 1.688 1.637 2.811-2.867zm-8.844.882c0 1.747.696 3.331 1.82 4.5h-12.82c-1.104 0-2-.896-2-2v-7h14.82c-1.124 1.169-1.82 2.753-1.82 4.5zm-5 .5h-5v1h5v-1zm3-2h-8v1h8v-1z"/></svg>}
                        maxLength="3"
                        max="200"
                        inputMode="numeric"
                        numericOnly
                        />
                  </div>

                  <div>
                     <Select
                        id="edit-org-months-between-credit"
                        name="months_between_credit"
                        placeholder="Aantal maanden tussen opwaarderen"
                        options={[
                           {name: "1", value: "1"}, {name: "2", value: "2"}, {name: "3", value: "3"}
                        ]}
                        defaultValue={organization && organization.months_between_credit ? String(organization.months_between_credit) : ""}
                        icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3v-2c0-.552.447-1 1-1s1 .448 1 1v2c0 .552-.447 1-1 1s-1-.448-1-1zm-12 1c.553 0 1-.448 1-1v-2c0-.552-.447-1-1-1-.553 0-1 .448-1 1v2c0 .552.447 1 1 1zm13 13v-3h-1v4h3v-1h-2zm-5 .5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5-4.5 2.019-4.5 4.5zm11 0c0 3.59-2.91 6.5-6.5 6.5s-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5zm-14.237 3.5h-7.763v-13h19v1.763c.727.33 1.399.757 2 1.268v-9.031h-3v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-9v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-3v21h11.031c-.511-.601-.938-1.273-1.268-2z"/></svg>}
                        />
                  </div>

               </div>

               <Select
                  id="edit-org-credit-day-of-month"
                  name="credit_day_of_month"
                  placeholder="Opwaarderingsdag"
                  options={
                     [...Array(28)].map((value, key) => { return {name: `${key + 1}e van de maand`, value: String(key + 1)} })
                  }
                  defaultValue={organization && organization.credit_day_of_month ? String(organization.credit_day_of_month) : ""}
                  icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3v-2c0-.552.447-1 1-1s1 .448 1 1v2c0 .552-.447 1-1 1s-1-.448-1-1zm-12 1c.553 0 1-.448 1-1v-2c0-.552-.447-1-1-1-.553 0-1 .448-1 1v2c0 .552.447 1 1 1zm13 13v-3h-1v4h3v-1h-2zm-5 .5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5-4.5 2.019-4.5 4.5zm11 0c0 3.59-2.91 6.5-6.5 6.5s-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5zm-14.237 3.5h-7.763v-13h19v1.763c.727.33 1.399.757 2 1.268v-9.031h-3v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-9v1c0 1.316-1.278 2.339-2.658 1.894-.831-.268-1.342-1.111-1.342-1.984v-.91h-3v21h11.031c-.511-.601-.938-1.273-1.268-2z"/></svg>}
                  noSearch
                  noSort
                  />

            </Form>

         </div>

      </div>
   )

}

export default function ProfileAdmin(props) {

   const [editUserIndex, setEditUserIndex] = useState(null);
   const [editOrganizationIndex, setEditOrganizationIndex] = useState(null);

   const [users, setUsers] = useState([]);
   const [organizations, setOrganizations] = useState([]);
   const [bookings, setBookings] = useState([]);

   const { addNotification } = useNotifications();

   const request = {
      users: {
          endpoint: "/api/v1/users",
          method: "GET",
      },
      organizations: {
         endpoint: "/api/v1/organizations",
         method: "GET",
     },
      bookings: {
         endpoint: "/api/v1/bookings?admin=true",
         method: "GET",
      },
   }
   const [data, loading, error] = useFetch(request);

   useEffect(() => {

      if (!loading) {
      
         if (data.users && data.users.data && data.organizations && data.organizations.data) {
            const users = [...data.users.data];
            for (let i = 0; i < users.length; i++) {

               users[i]['full_name'] = users[i]['first_name'] + " " + users[i]['last_name'];
               users[i]['type'] = users[i]['type'] == 'admin' ? "Beheerder" : (users[i]["type"] == 'user' ? "Gebruiker": "Aanbieder");
               users[i]['pretty_created_at'] = new Date(users[i]['created_at']).toLocaleDateString();
               users[i]['options'] = <div className="edit-buttons">
                  <span onClick={() => { setEditUserIndex(i); setEditOrganizationIndex(null); }} ><svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.481 15.659c-1.334 3.916-1.48 4.232-1.48 4.587 0 .528.46.749.749.749.352 0 .668-.137 4.574-1.492zm1.06-1.061 3.846 3.846 11.321-11.311c.195-.195.293-.45.293-.707 0-.255-.098-.51-.293-.706-.692-.691-1.742-1.74-2.435-2.432-.195-.195-.451-.293-.707-.293-.254 0-.51.098-.706.293z" fillRule="nonzero"/></svg></span>
               </div>;
               users[i]['num_tokens'] = users[i]['num_tokens'] ? users[i]['num_tokens'].toLocaleString() : null;

            }
            setUsers(users);
            setOrganizations([...data.organizations.data]);
         }

         if (data.bookings && data.bookings.data) {
            const bookings = [...data.bookings.data];
            for (let i = 0; i < bookings.length; i++) {
               bookings[i]['description'] = bookings[i]['description'] && bookings[i]['description'].length < 30 ? bookings[i].description : bookings[i].description.substring(0, 27) + '...';
               bookings[i]['full_name'] = bookings[i]['first_name'] + ' ' + bookings[i]['last_name']
               bookings[i]['pretty_created_at'] = new Date(bookings[i]['created_at']).toLocaleDateString();
               bookings[i]['options'] = <>
                  
                  {bookings[i].editor_status !== "confirmed" && bookings[i].editor_status !== "cancelled" && bookings[i].user_status !== "cancelled" &&
                     <Form
                        id="booking-confirm"
                        action={"/api/v1/bookings/" + bookings[i]["booking_id"] + "/:confirm"}
                        submitBtnText="Bevestigen"
                        submitCallback={() => { 
                           addNotification('Je hebt de boeking bevestigd.');
                        }}
                        oneLineSubmitIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>}
                        oneLineSubmit
                        noLoad
                        />
                  }

                  {bookings[i].editor_status !== "cancelled" && bookings[i].user_status !== "cancelled" &&

                     <Form
                        id="booking-cancel"
                        action={"/api/v1/bookings/" + bookings[i]["booking_id"] + "/:cancel"}
                        submitBtnText="Annuleren"
                        submitCallback={() => { 
                           addNotification('Je hebt de boeking geannuleerd.');
                           window.location.reload();
                        }}
                        oneLineSubmitIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>}
                        oneLineSubmit
                        noLoad
                        />

                  }
               </>;
            }
            setBookings(bookings);
         }

      }

   }, [loading]);

   function arrayToCSV(data) {
      const headers = Object.keys(data[0]);
      const csvRows = [];
  
      // Add the headers row
      csvRows.push(headers.join(','));
  
      // Add the data rows
      for (const row of data) {
          const values = headers.map(header => {
              const escaped = ('' + row[header]).replace(/"/g, '\\"');
              return `"${escaped}"`;
          });
          csvRows.push(values.join(','));
      }
  
      return csvRows.join('\n');
  }
   
   function downloadCSV(data, filename = 'payments.csv') {
      if (!Array.isArray(data) || data.length === 0) return;

      const csvString = arrayToCSV(data);
      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
  
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', filename);
      a.click();
   }

   return (

      <div id="admin-panel">

         <h3>Gebruikersbeheer</h3>
         <p>Hier kun je gebruikers inzien en hun gegevens wijzigen.</p>

         <div id="admin-panel-mobile">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm1-6h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"/></svg>
            <p>Het lijkt erop dat je op een mobiel apparaat werkt. Deze weergave is hier niet voor geoptimaliseerd.</p>
         </div>

         <div className="admin-queries">

            <Button
               id="add-users" 
               name="Gebruikers toevoegen"
               customIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z"/></svg>}
               onClick={() => {
                  setEditUserIndex('new'); setEditOrganizationIndex(null);
               }}
               />

            <Form
               action={"/api/v1/users/:query?type=user_export"}
               submitBtnText={"User export"}
               submitCallback={(e, data) => {
                  downloadCSV(data.data.user_export, "user_export.csv");
               }}
               noLoad
               noCta
               />

         </div>



         <Table
            headers={[
               {field: 'full_name', label: 'Volledige naam', sortable: true, searchable: true},
               {field: 'organization', label: 'Organisatie', sortable: true, searchable: true},
               {field: 'type', label: 'Type', className: 'tag', tagMap: {
                  Beheerder: {
                     icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 19h14v3h-14v-3zm18-11c-.729 0-1.295.79-.832 1.521-1.229 1.474-3.371 2.971-4.355 2.438-1.201-.65-.277-3.302.451-4.982.958.15 1.736-.591 1.736-1.477 0-.829-.672-1.5-1.5-1.5s-1.5.671-1.5 1.5c0 .452.204.853.52 1.127-.645 1.643-2.325 3.807-3.41 3.591-1.347-.268-1.69-3.448-1.685-5.287.62-.183 1.075-.751 1.075-1.431 0-.829-.672-1.5-1.5-1.5s-1.5.671-1.5 1.5c0 .68.455 1.248 1.075 1.432.006 1.839-.338 5.019-1.685 5.287-1.084.216-2.765-1.949-3.41-3.592.316-.274.52-.675.52-1.127 0-.829-.672-1.5-1.5-1.5s-1.5.671-1.5 1.5c0 .886.778 1.627 1.736 1.476.729 1.681 1.652 4.333.451 4.982-.984.533-3.127-.964-4.355-2.438.463-.73-.103-1.52-.832-1.52-.553 0-1 .448-1 1 0 .704.726 1.221 1.413.905 1.134 1.264 3.335 4.242 3.587 7.095h14c.252-2.853 2.453-5.831 3.587-7.095.687.316 1.413-.201 1.413-.905 0-.552-.447-1-1-1z"/></svg>,
                  },
                  Aanbieder: {
                     icon: <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15.4 19.25c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75zm-1.4-14.25c0-.53-.47-1-1-1h-10c-.53 0-1 .47-1 1v14c0 .53.47 1 1 1h10c.53 0 1-.47 1-1zm1.4 10.625c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75zm0-3.625c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75zm0-3.625c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75zm0-3.625c0-.414.336-.75.75-.75h5.1c.414 0 .75.336.75.75s-.336.75-.75.75h-5.1c-.414 0-.75-.336-.75-.75z" fillRule="nonzero"/></svg>,
                  },
                  Gebruiker: {
                     icon: <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd"><path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7zm-3-8c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"/></svg>
                  }
               }},
               {field: 'pretty_created_at', alignRight: true, label: 'Sinds'},
               {field: 'num_tokens', label: 'Tegoed', alignRight: true},
               {field: 'options', alignRight: true, label: 'Opties'},
            ]}
            data={users}
            rowsPerPage={20}
            />

         <h3>Organisatiebeheer</h3>
         <p>Accounts die gekoppeld zijn aan een organisatie krijgen tegoed toegekend of zien een afgestemd aanbod.</p>

         <AdminOrganizations
            organizations={organizations}
            setEditUserIndex={setEditUserIndex}
            setEditOrganizationIndex={setEditOrganizationIndex}
            />

         <h3>Financieel beheer.</h3>
         <p>Dit is het overzicht van alle betalingen.</p>
         
         {/* <Form
            id="settle-bookings" 
            action="/api/v1/bookings/:settle"
            submitBtnText="Boekingen uitbetalen"
            submitCallback={(e, data) => {
               downloadCSV(data.data.settlements, "settlements.csv");
               downloadCSV(data.data.invoices, "invoices.csv");
            }}
            noLoad
            noCta
            /> */}

         <div className="admin-queries">
            {["Bookings Payable", "Bookings Payable Beneficiary", "Bookings Billable"].map((requestType, key) => {
               
               const requestTypeQuery = requestType.replaceAll(' ', '_').toLowerCase();

               return (
                  <Form
                     action={"/api/v1/bookings/:query?type=" + requestTypeQuery}
                     key={key}
                     submitBtnText={requestType}
                     submitCallback={(e, data) => {
                        downloadCSV(data.data[requestTypeQuery], requestTypeQuery + ".csv");
                     }}
                     noLoad
                     noCta
                     />
               )

            })}
         </div>

         <Table
            headers={[
               {field: 'location_name', label: 'Volledige naam', sortable: true, searchable: true},
               {field: 'description', label: 'Omschrijving', sortable: true, searchable: true},
               {field: 'full_name', label: 'Geboekt door', sortable: true, searchable: true},
               {field: 'editor_status', label: 'Aanbiedersstatus', className: 'tag', alignRight: true, tagMap: {}},
               {field: 'booker_status', label: 'Gebruikersstatus', className: 'tag', alignRight: true, tagMap: {}},
               {field: 'price', alignRight: true, label: 'Prijs'},
               {field: 'pretty_created_at', alignRight: true, label: 'Boekingsdatum'},
               {field: 'options', alignRight: true, label: 'Opties'},
            ]}
            data={bookings}
            rowsPerPage={20}
            />
         

         {(editUserIndex !== null && editUserIndex !== 'new') &&
            <AdminEditUser
               editUserIndex={editUserIndex}
               users={users}
               setUsers={setUsers}
               organizations={organizations}
               closeFormFn={() => { setEditUserIndex(null); }}
               />
         }

         {(editUserIndex !== null && editUserIndex === 'new') &&
            <AdminAddUsers
               organizations={organizations}
               closeFormFn={() => { setEditUserIndex(null); }}
               />
         }

         {editOrganizationIndex !== null &&
            <AdminEditOrganization 
               editOrganizationIndex={editOrganizationIndex}
               organizations={organizations}
               setOrganizations={setOrganizations}
               closeFormFn={() => { setEditOrganizationIndex(null); }}
               />
         }

      </div>

   );

}